<template>
  <article class="about-us" :class="{ mobile: isMobile }">
    <Hero heading="About Electrify America" :limitMobileImageHeight="false">
      <template slot="image">
        <picture>
          <source
            srcset="
              @/assets/images/WhatToExpect/what-to-expect-hero-desktop.png,
              @/assets/images/WhatToExpect/what-to-expect-hero-desktop@2x.png 2x
            "
            media="(min-width: 750px)"
            alt="Electrify America charging stations"
          />
          <source
            alt="Electrify America charging stations"
            srcset="
              @/assets/images/WhatToExpect/what-to-expect-hero.png,
              @/assets/images/WhatToExpect/what-to-expect-hero@2x.png 2x
            "
          />
          <img src="@/assets/images/WhatToExpect/what-to-expect-hero.png" alt="Electrify America charging stations" />
        </picture>
      </template>
      <template>
        <div class="hero-content">
          <h3 class="headline-3">Our{{ '\xa0' }}Mission</h3>
          <p>
            Electrify America will be a catalyst for promoting Zero-Emission Vehicle (ZEV) adoption by offering
            transformative customer-centric infrastructure and energy management solutions.
          </p>
        </div>
      </template>
    </Hero>

    <div class="l-one__col l-one__col--x-wide values">
      <h2 class="who-we-are__title">Who We Are</h2>
      <p>
        We are the electric vehicle (EV) driver’s trusted partner for reliable, Hyper-Fast charging. We’re building a
        future where EV charging is approachable, accessible, and powered by a network drivers can depend on — today and
        tomorrow.
      </p>
      <br />

      <h2>Our Values</h2>

      <div class="l-three-col values__container">
        <div class="values__card">
          <img src="../../assets/images/AboutUs/environment_icon.svg?external" alt="" />

          <div class="headline4">
            <h3 class="sr-only">Environmental Values</h3>
            Environment
          </div>

          <p>
            We are committed to a net zero carbon footprint for all energy delivered to drivers. Our network is backed
            by 100% renewable energy via renewable energy certificates.
          </p>
        </div>

        <div class="values__card">
          <img src="../../assets/images/AboutUs/community_icon.svg?external" alt="" />

          <div class="headline4">
            <h3 class="sr-only">Community Values</h3>
            Community
          </div>

          <p>
            Our mission is to unlock equitable electrification for all through community investment, specifically in
            underrepresented communities.
          </p>
        </div>

        <div class="values__card">
          <img src="../../assets/images/AboutUs/people_icon.svg?external" alt="" />

          <div class="headline4">
            <h3 class="sr-only">Educational Values</h3>
            People
          </div>

          <p>
            As the largest open Hyper-Fast charging network in the U.S., we invest in educating our communities on the
            benefits of driving electric.
          </p>
        </div>
      </div>

      <div class="values__cta-container">
        <router-link :to="{ name: 'our-values-en' }" class="button values__cta"
          >Learn More<span class="sr-only">about our values</span></router-link
        >
      </div>
    </div>

    <div class="l-one__col l-one__col--wide investment">
      <div class="investment__image-container">
        <img
          :src="
            !isMobile
              ? require('@/assets/images/AboutUs/investment-plan-img.png')
              : require('@/assets/images/AboutUs/investment-plan-image-mobile.png')
          "
          alt=""
          class="investment__image"
        />
      </div>

      <div class="investment__content">
        <div class="investment__content-wrapper">
          <h2>Investment Plan</h2>
          <p>
            To promote Zero-Emission Vehicle (ZEV) adoption, we are investing $2 billion in infrastructure, access, and
            education programs in the United States.
          </p>
          <router-link :to="{ name: 'our-plan-en' }" class="button-link"
            >Learn More <span class="sr-only">about EA's Investment Plan</span> <arrow class="arrow"
          /></router-link>
        </div>
      </div>
    </div>

    <div class="sustainability l-one__col l-one__col--x-wide">
      <HeroWithContentOverlay :overlayBG="false" :darkBG="false" fullWidthMobile mobileResponsive="above">
        <template #image>
          <picture>
            <source
              srcset="
                @/assets/images/AboutUs/sustainability-image.png,
                @/assets/images/AboutUs/sustainability-image@2x.png 2x
              "
              media="(min-width: 850px)"
            />
            <source srcset="@/assets/images/AboutUs/sustainability-image-mobile.png" />
            <img
              loading="lazy"
              src="@/assets/images/AboutUs/sustainability-image-mobile.png"
              alt="Hyper fast charging station HMI display with mobile pay instructions, station ID, and Customer Assistance number 1-833-632-2778"
            />
          </picture>
        </template>
        <div class="sustainability__content">
          <h3>Renewable Energy & Sustainability</h3>
          <p>
            Electrify America is committed to a net zero carbon footprint for all energy delivered to drivers. Today
            when you charge with us, the energy delivered from our network is backed by 100% renewable energy via
            renewable energy certificates.
          </p>
          <router-link class="button-link" :to="{ name: 'renewable-energy-en' }">
            Learn More<span class="sr-only">about renewable energy and sustainability</span
            ><arrow class="arrow"></arrow>
          </router-link>
        </div>
      </HeroWithContentOverlay>
    </div>
  </article>
</template>

<script>
import Hero from '@/components/Hero/Hero.vue';
import arrow from '@/assets/images/Global/arrow.svg';
import HeroWithContentOverlay from '@/components/HeroWithContentOverlay/HeroWithContentOverlay.vue';

export default {
  name: 'about-us',
  metaInfo: {
    title: 'About Electrify America | Electrify America',
    meta: [
      {
        name: 'description',
        content:
          'Electrify America’s goal is to promote greater Zero-Emission Vehicle (ZEV) adoption by offering an ultrafast and convenient charging network. Learn about us.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/about-us/' }],
  },
  components: { Hero, arrow, HeroWithContentOverlay },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    isTiny() {
      return this.$resize && this.$mq.below(750);
    },
  },
};
</script>

<style scoped lang="scss">
.about-us {
  .l-one--has-bg {
    padding-top: 30px;
    @media (min-width: 750px) {
      padding-top: 100px;
    }
  }

  .hero-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 0 auto;

    h3 {
      margin-right: 32px;
      min-width: 180px;
      width: 180px;
    }

    p {
      flex-grow: 1;
      margin: 0;
    }
  }

  .values {
    padding-top: 64px;
    padding-bottom: 64px;

    h2 {
      padding-bottom: 50px;
    }

    .who-we-are__title {
      padding-bottom: 25px;
    }

    &__card {
      box-sizing: border-box;
      width: calc(100% / 3 - 12px);
      border: solid 1px $c-gray-light;
      border-radius: 16px;
      margin-right: 24px;
      margin-bottom: 24px;
      padding: 40px 28px;

      &:last-child {
        margin-right: 0;
      }

      img {
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 0;
      }
    }

    &__cta {
      text-transform: uppercase;

      &-container {
        display: flex;
        justify-content: center;
      }
    }
  }

  .investment {
    display: flex;
    padding-bottom: 64px;

    &__image {
      width: 100%;
    }

    &__content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;

      &-wrapper {
        width: 75%;
      }
    }
  }

  .sustainability {
    padding-bottom: 64px;

    &__content {
      display: flex;
      flex-direction: column;
    }

    ::v-deep .button-link {
      order: -1;
    }
  }
  &.mobile {
    .hero-content {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }

    .values {
      &__container {
        padding-left: 0;
        padding-right: 0;
      }

      &__card {
        width: 100%;
        margin-right: 0;
      }
    }

    .investment {
      flex-direction: column;

      &__content-wrapper {
        width: 100%;
        padding-top: 24px;
      }
    }

    .sustainability {
      ::v-deep .button-link {
        margin-bottom: 20px;
        display: block;
        order: 3;
      }
      ::v-deep .hero-with-overlay__content {
        padding: 0;
      }
      ::v-deep .hero-with-overlay__image-container {
        box-sizing: border-box;
        padding: 0;
      }

      &__content {
        margin-bottom: 24px;
      }
    }
  }
}
</style>
